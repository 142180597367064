export const isEmpty = element => !element.length

export const isPathnameEqualTo = path => window.location.pathname === path

export const size = element => element.length

export const getFromLocalStorage = key => window.localStorage.getItem(key)

export const setToLocalStorage = (key, value) =>
  window.localStorage.setItem(key, value)

export const makeUniqueFile = async file => {
  const arrayBuffer = await file.arrayBuffer()
  const uint8Array = new Uint8Array(arrayBuffer)
  const newArray = new Uint8Array(uint8Array.length + 1)
  newArray.set(uint8Array)
  newArray[uint8Array.length] = 0
  return new File([newArray], file.name, { type: file.type })
}
